html {
  height: 100%;
}

body {
  height: 95%;
  background-color: rgb(61 61 61);
  font-family: "Lucida Console", Monospace;
}

#root {
  height: 100%;
}

h1, h2, h3, hr, .info-title {
  color: #ffff00;
}

.row>* {
  padding-left: 5px;
}

.center-yellow-text {
  text-align: center;
  color: #ffff00;
}

.next-button {
  padding-top: 30px;
}

.issue-message-title {
  color: #ffff00;
  text-decoration: underline;
  text-align: center;
  padding-top: 5px;
}

.slick-list,.slick-track,.container {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

.row {
  margin-left: 0px;
}

.text-on-right {
  text-align: right;
}

.slick-slide > div > div {
  height: 100%;
}

.btn-success, .btn-success:hover, .btn-success:active, .btn-success:visited, .btn-success:disabled {
  background-color: #ffff00 !important;
  color: rgb(23, 23, 23);
}

.btn-outline-success, .btn-outline-success:hover, .btn-outline-success:active, .btn-outline-success:visited {
  color: #ffff00;
  border-color: #ffff00;
}

.btn-outline-success:hover {
  background-color: rgb(23, 23, 23);
}

.btn-outline-success.active {
  background-color: #ffff00 !important;
  border-color: #ffff00;
  color: rgb(23, 23, 23);
}

.vertical-center {
  display: flex;
  align-items: center;
}

.slick-arrow {
  display: none;
}

a {
  color: yellow;
}

.slick-dots li {
  width: 100px;
  margin: 0px;
}

.slick-dots > ul {
  padding-left: 0px;
}